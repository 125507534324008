import React from "react";
import './episodes.css'
import {DefaultPlayer as Video} from 'react-html5video'
import 'react-html5video/dist/styles.css'
import E1 from '../assets/videos/E1AUXGODRADIO.mp4'
import phone from '../assets/videos/E1AUXGODRADIO-iphone.mp4'
import pic from '../assets/auxgodlogo.png'



const Episodes = () => {
    return (

        <div className="e-container">
            
            <text className="title">Episodes</text>

            <div className="episode-list">

                <div className="e-1">
                    <Video 
                    autoplay
                    loop
                    className='vid'
                    >
                        <source src={E1} type="video/mp4"/>
                    </Video>

                    <div className="words">AuxGod Radio____ep.1</div>


                    <Video autoplay loop className='iphone' poster={pic}>
                        <source src={phone} type="video/mp4"/>
                    </Video>



                </div>

                
                


            </div>
        </div>

    )
}

export default Episodes;